<template>
  <div>
    <div id="feature" class="pstl-green">
      <div id="feature-text" class="box">
        <span class="header-font">WILDER BRAMLEY</span>
        <img src="@/assets/underline.png" class="underline" />
        <div class="nrml-text bold">
          We're a community led group, here to encourage the people of Bramley
          to connect with nature and act to protect it.
        </div>
        <br />
        <div class="nrml-text">
          Whether that's through local wildlife encounters, increasing
          biodiversity in back gardens or creating community spaces for nature
          to thrive.
        </div>
      </div>
      <img id="feature-img" src="@/assets/frith.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
};
</script>
