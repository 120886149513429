<template>
  <div>
    <div id="main-column">
      <h1>Wilder Bramley Membership</h1>
      <p>
        If you wish to become a member of Wilder Bramley and support our aims of
        protecting our local nature and raising awareness of environmental
        issues please complete the membership form below and email it to
        <a href="mailto:wilder.bramley@gmail.com">wilder.bramley@gmail.com</a>.
        We will contact you regarding payment.
      </p>
      <a href="Wilder Bramley Membership form issue 2 May2024.docx"
        ><button>download membership form</button></a
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
