<template>
  <div>
    <div id="main-picture">
      <img src="@/assets/wildbram3.jpg" />
    </div>
    <div id="main-column">
      <h1>Events</h1>
      <table>
        <tr>
          <td class="date">12 Feb</td>
          <td class="event">Bee Garden</td>
          <td class="location">Clift Meadow</td>
        </tr>
        <tr>
          <td class="date">12 Feb</td>
          <td class="event">Bee Garden</td>
          <td class="location">Clift Meadow</td>
        </tr>
        <tr>
          <td class="date">12 Feb</td>
          <td class="event">Bee Garden</td>
          <td class="location">Clift Meadow</td>
        </tr>
        <tr>
          <td class="date">12 Feb</td>
          <td class="event">Bee Garden</td>
          <td class="location">Clift Meadow</td>
        </tr>
        <tr>
          <td class="date">12 Feb</td>
          <td class="event">Bee Garden</td>
          <td class="location">Clift Meadow</td>
        </tr>
        <tr>
          <td class="date">12 Feb</td>
          <td class="event">Bee Garden</td>
          <td class="location">Clift Meadow</td>
        </tr>
        <tr>
          <td class="date">12 Feb</td>
          <td class="event">Bee Garden</td>
          <td class="location">Clift Meadow</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
