import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import EventsView from "../views/EventsView.vue";
import MembershipView from "../views/MembershipView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/events",
    name: "events",
    component: EventsView,
  },
  {
    path: "/membership",
    name: "membership",
    component: MembershipView,
  },

  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  routes,
});

export default router;
