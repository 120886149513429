<template>
  <div>
    <div id="main-picture">
      <img src="@/assets/wildbram.jpg" />
    </div>
    <div id="main-column">
      <h1>About</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
        tincidunt magna at risus volutpat, a mattis elit ultricies. Praesent non
        sem a purus tristique lacinia id ac leo. Proin tincidunt ante tortor,
        nec elementum elit lacinia vel. Etiam laoreet leo egestas lacus sodales
        facilisis. Proin vitae molestie orci. Nulla quis metus blandit, blandit
        elit sit amet, euismod augue. Maecenas et pharetra lacus. Interdum et
        malesuada fames ac ante ipsum primis in faucibus.
      </p>
      <p>
        Nulla sem nibh, maximus in cursus egestas, rhoncus at libero. Ut porta
        tempus nisl et aliquet. Nulla in nunc non purus consectetur ornare eget
        euismod est. Nam tempus pretium est, non aliquet elit eleifend vel.
        Aliquam metus sem, auctor sit amet massa vitae, lacinia viverra leo.
        Vivamus ex arcu, pellentesque ut est a, vulputate semper dui. Nulla ut
        laoreet arcu. Nunc ac luctus turpis. Sed ac arcu neque. Ut viverra lorem
        ac risus ultrices varius.
      </p>
      <img src="@/assets/wildbram4.jpg" class="mini-pic" />
      <p>
        Aliquam suscipit, sem a euismod dignissim, velit mi porttitor ex, quis
        dignissim diam purus vel turpis. Phasellus ultricies aliquet odio eu
        viverra. Donec ut nibh at nulla fermentum posuere. Vestibulum
        condimentum pharetra massa hendrerit interdum. Sed hendrerit lobortis
        malesuada. Suspendisse potenti. In malesuada in neque vel sollicitudin.
        Nulla sit amet lacinia urna. Suspendisse ultricies eros sed quam
        dignissim, laoreet egestas dui egestas. Ut nunc ante, vestibulum quis
        dictum nec, ullamcorper ut ligula. Fusce malesuada elit risus, in
        pulvinar nisl imperdiet nec. Lorem ipsum dolor sit amet, consectetur
        adipiscing elit. Etiam quis erat lorem. Donec vitae accumsan elit.
        Mauris et lorem tincidunt, interdum lacus sit amet, rutrum nibh.
      </p>

      <p>
        Nullam blandit sem sed auctor congue. Morbi pellentesque diam semper
        libero auctor convallis. Fusce nec erat id augue ultrices tristique id
        vel enim. Aenean congue, mauris nec venenatis euismod, quam massa
        vulputate lectus, ut hendrerit augue augue sit amet arcu. Proin
        pellentesque cursus diam, in tincidunt lorem mattis eu. Phasellus vel
        magna sem. In id viverra justo, vitae euismod ipsum. Sed lobortis, ipsum
        a condimentum lacinia, risus mi facilisis nibh, eu porttitor velit
        ligula nec lacus. Cras gravida euismod nisi vitae convallis.
      </p>
      <img src="@/assets/wildbram5.jpg" class="mini-pic" />
      <p>
        Nam a rutrum turpis. Sed nec tellus at sem venenatis faucibus sit amet
        at elit. Phasellus sollicitudin efficitur lacinia. Sed vel ipsum non
        velit auctor vulputate. Curabitur sit amet tincidunt est, eget iaculis
        ante. Nullam porttitor, justo id tempor mattis, magna odio gravida
        augue, non vehicula mauris mauris id ipsum. Vivamus et tortor id nisi
        hendrerit volutpat. Nulla facilisi. Mauris ultricies semper efficitur.
        Sed ultricies nunc quis nunc tristique blandit. Orci varius natoque
        penatibus et magnis dis parturient montes, nascetur ridiculus mus. Ut et
        massa purus. Etiam ut eros vitae justo facilisis finibus vel vel turpis.
        Donec vitae luctus quam. Nulla ac diam quis neque aliquet tincidunt.
      </p>
      <p>
        Nulla sem nibh, maximus in cursus egestas, rhoncus at libero. Ut porta
        tempus nisl et aliquet. Nulla in nunc non purus consectetur ornare eget
        euismod est. Nam tempus pretium est, non aliquet elit eleifend vel.
        Aliquam metus sem, auctor sit amet massa vitae, lacinia viverra leo.
        Vivamus ex arcu, pellentesque ut est a, vulputate semper dui. Nulla ut
        laoreet arcu. Nunc ac luctus turpis. Sed ac arcu neque. Ut viverra lorem
        ac risus ultrices varius.
      </p>
      <img src="@/assets/wildbram2.jpg" class="mini-pic" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
